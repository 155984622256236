<template>
  <div>
    <b-modal
      id="change-state"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ShipToManufactory')"
      hide-footer
      size="lg"
      @close="onCancel"
    >
      <b-form>
        <div style="display:flex;gap: 15px;padding-bottom: 5px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('SelectD')"
          >
            <date-picker
              v-model="movementDate"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('SenderLocation')"
          >
            <vue-select
              v-model="fromLocation"
              :options="getLocationsMoving"
              label="locationName"
              :reduce="(e) => e.locationId"
              :placeholder="$t('From')"
            />
          </b-form-group>
        </div>
        <div style="display: flex; gap:15px;padding-bottom: 5px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('ReceiverLocation')"
          >
            <vue-select
              v-model="toLocation"
              :options="getLocationsMoving"
              label="locationName"
              :reduce="(e) => e.locationId"
              :placeholder="$t('To')"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('AssignTeam')"
          >
            <vue-select
              v-model="teams"
              :options="getTeamsNames"
              label="teamName"
              :reduce="(e) => e.teamNameId"
              :placeholder="$t('AssignTeam')"
              style="width: 100%;"
            />
          </b-form-group>
        </div>
        <b-form-group
          :label="$t('Orders')"
        >
          <vue-select
            v-model="orderItemIdss"
            :options="orderItemIds"
            :reduce="(e) => e.orderItemId"
            label="orderNumber"
            style="width: 100%;"
            :multiple="true"
            :placeholder="$t('Select')"
          />
        </b-form-group>
      </b-form>
      <div style="display:flex;padding-top: 5px;gap:5px">
        <b-form-checkbox
          v-model="additionalMaterials"
        />
        <p
          style="margin:0;color:#FF274F"
        >
          {{ $t('AddMaterialAdditional') }}
        </p>
      </div>
      <p
        v-if="hasDuplicates"
        style="margin-top:10px;margin-bottom:5px;color:#FF274F"
      >
        {{ $t('DuplicateMaterial') }}
      </p>
      <div
        v-if="additionalMaterials"
        class="modal-card__wrapper"
        style="align-items: center"
      >
        <div
          v-for="(formItem, k) in form"
          :key="k"
          class="modal-card"
        >
          <b-form-group
            id="input-group-3"
            :label="`${$t('SelectMaterialType')}:`"
          >
            <i
              class="modal-card__remove"
              @click="removeMaterialItem(formItem.id)"
            ><b-icon-trash /></i>
            <div class="flex radio-form">
              <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                <div
                  class="flex aic"
                  @click="resetMeter(formItem)"
                >
                  <input
                    :id="`one${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Meter"
                    required
                  >
                  <label :for="`one${k}`">{{ $t('Meter') }}</label>
                </div>
                <div
                  class="flex aic"
                  @click="resetPackage(formItem)"
                >
                  <input
                    :id="`one${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Package"
                    required
                  >
                  <label :for="`one${k}`">{{ $t('Package') }}</label>
                </div>
                <div
                  class="flex aic"
                  @click="resetSet(formItem)"
                >
                  <input
                    :id="`one${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Set"
                    required
                  >
                  <label :for="`one${k}`">{{ $t('Set') }}</label>
                </div>
                <div
                  class="flex aic"
                  @click="resetPair(formItem)"
                >
                  <input
                    :id="`one${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Pair"
                    required
                  >
                  <label :for="`one${k}`">{{ $t('Pair') }}</label>
                </div>
                <div
                  class="flex aic"
                  @click="resetVerse(formItem)"
                >
                  <input
                    :id="`one${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Verse"
                    required
                  >
                  <label :for="`one${k}`">{{ $t('BeadedStrand') }}</label>
                </div>
                <div
                  class="flex aic"
                  @click="resetPart(formItem)"
                >
                  <input
                    :id="`two${k}`"
                    v-model="formItem.measurementType"
                    type="radio"
                    value="Parts"
                    required
                  >
                  <label :for="`two${k}`">{{ $t('Part') }}</label>
                </div>
              </div>
            </div>
          </b-form-group>

          <div class="search-toggle">

            <p>{{ $t('SearchBy') }}</p>
            <div>
              <p
                :class="{ active2: toggleSearch === 'MaterialName' }"
                @click="showMaterialName(), toggleSearch = 'MaterialName'"
              >
                {{ $t('Name') }}
              </p>
              <p
                :class="{ active2: toggleSearch === 'Description' }"
                @click="showDescription(), toggleSearch = 'Description'"
              >
                {{ $t('Description') }}
              </p>
            </div>
          </div>

          <b-form-group
            id="input-select-1"
            label-for="select-1"
          >
            <vue-select
              v-if="formItem.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              required
              @search="onSearchM"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242f6e; color: white'"
                  :disabled="pageM == 1"
                  @click="prevMeter"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                  @click="nextMeter"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Meter' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              required
              @search="onSearchMDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242f6e; color: white'"
                  :disabled="pageM == 1"
                  @click="prevMeter"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                  @click="nextMeter"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              required
              @search="onSearchPack"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePack == 1"
                  @click="prevPackage"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                  @click="nextPackage"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              required
              @search="onSearchPackDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePack == 1"
                  @click="prevPackage"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                  @click="nextPackage"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              required
              @search="onSearchSet"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageSet == 1"
                  @click="prevSet"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                  @click="nextSet"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              required
              @search="onSearchSetDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageSet == 1"
                  @click="prevSet"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                  @click="nextSet"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              required
              @search="onSearchPair"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePair == 1"
                  @click="prevPair"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                  @click="nextPair"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              required
              @search="onSearchPairDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePair == 1"
                  @click="prevPair"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                  @click="nextPair"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              required
              @search="onSearchVerse"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageVerse == 1"
                  @click="prevVerse"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                  @click="nextVerse"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>

            <vue-select
              v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              required
              @search="onSearchVerseDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageVerse == 1"
                  @click="prevVerse"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                  @click="nextVerse"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>

            <vue-select
              v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'MaterialName'"
              v-model="formItem.materialVariantId"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
              :placeholder="$t('SelectMaterialVariant')"
              label="materialVariantName"
              style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
              required
              @search="onSearchP"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #0b0033; color: white'"
                  :disabled="pageP == 1"
                  @click="prevPart"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  type="button"
                  :style="getButtonStylePart"
                  :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                  @click="nextPart"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'Description'"
              v-model="formItem.materialVariantId"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              label="materialVariantName"
              style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
              required
              @search="onSearchPDesc"
              @input="currentStock(formItem.materialVariantId.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #0b0033; color: white'"
                  :disabled="pageP == 1"
                  @click="prevPart"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  type="button"
                  :style="getButtonStylePart"
                  :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                  @click="nextPart"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <p
              v-if="errors.materialVariantError"
              style="color: red"
            >
              {{ $t('SelectMaterialVariant') }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="formItem.materialVariantId"
            :label="`${$t('Price')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="formItem.materialVariantId.pricePerMeter"
              type="number"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="`${$t('Length')}:`"
          >
            <b-input
              v-model="formItem.quantity"
              type="number"
              required
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.quantity)}€` : null }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="formItem.materialVariantId ? formItem.materialVariantId.materialVariantId === clickedMaterialVariantId : false"
            :label="`${$t('Stock')}:`"
            style="width: 100%;"
          >
            <b-form-input
              v-model="getSavedStock.stockValue"
              type="number"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="`${$t('Notes')}:`"
            style="width: 100%;"
          >
            <b-form-textarea
              v-model="formItem.notes"
            />
          </b-form-group>

        </div>
        <b-button
          type="button"
          style="margin: 5px 0; background: #FF274F;"
          size="sm"
          class="button"
          @click="addNewRow"
        >
          <p style="margin: 0">
            + {{ $t('AddMaterial') }}
          </p>
        </b-button>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px; background: #FF274F !important;"
          class="buttonSubmit"
          :disabled="isButtonDisabled"
          @click="onSubmit"
        >
          {{ $t('Confirm') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['orderItemIds'],
  data() {
    return {
      additionalMaterials: false,
      teams: null,
      fromLocation: null,
      toLocation: null,
      movementDate: moment().format('YYYY-MM-DD'),
      orderItemIdss: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      pageSize: 15,
      form: [],
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      errors: {
        duplicateError: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLocationsMoving',
      'getTeamsNames',
      'getProductSize',
      'getSizeTypes',
      'getCurrentPageForMVM',
      'getTotalPagesForMVM',
      'getCurrentPageForMVP',
      'getTotalPagesForMVP',
      'getFilteredMV',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialVariantColorNameLike',
      'getMaterialVaraintsToAddMainDressCategoryColorNameLike',
      'getTotalItemsPages',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByVerse',
      'getMaterialsVByPair',
      'getSavedStock',
      'getFilterVariantsByCodeLike',
      'getLoggedInUser']),
    hasDuplicates() {
      const materialVariantIds = this.form.map((item) => { return item.materialVariantId?.materialVariantId });
      const uniqueIds = new Set(materialVariantIds);
      return uniqueIds.size < materialVariantIds.length;
    },
    isButtonDisabled() {
      return (
        !this.teams
        || !this.fromLocation
        || !this.toLocation
        || !this.movementDate
        || this.orderItemIdss.length === 0
      );
    },
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    totalCost() {
    // Initialize a variable to store the total cost
      let total = 0;

      // Iterate through each item in dress.formItem
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (item.materialVariantId) {
        // Check if the item has a name (material variant selected)
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else {
          // For items measured in parts, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.quantity;
          }
        }
      }

      // Return the total cost with 2 decimal places and in the correct format
      return `${total.toFixed(2)}`;
    },
  },
  watch: {
    additionalMaterials(value) {
      if (value == false) {
        this.form = []
      }
    },
    orderItemIds(value) {
      if (value) {
        this.orderItemIdss = value
      }
    },
    form: {
      handler(newValue) {
        console.log('lala', this.form)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.form) {
          if (item.materialVariantId.materialVariantId == undefined) {
            this.isUndefined = true
            return;
            // eslint-disable-next-line no-else-return
          } else {
            this.isUndefined = false
          }
        }
        const uniqueValues = new Set(newValue.map((v) => { return v.materialVariantId.materialVariantId }))
        if (uniqueValues.sizes < newValue.length) {
          this.errors.duplicateError = true
        } else {
          this.errors.duplicateError = false
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['productSizes', 'currentStockByVariant', 'resetAllFiltered',
      'getMaterialVariantsToMDCPagination', 'loadSizeTypes',
      'loadMaterialVaraintsCategoryNameLike', 'testResetMV', 'filterVariantByColor', 'filterVariantByCode']),
    onSubmit() {
      const orders = this.orderItemIdss.map((item) => { return item.orderItemId ? item.orderItemId : item })
      const materials = this.form.map((item) => {
        return {
          materialVariantId: item.materialVariantId.materialVariantId,
          quantity: item.quantity,
          notes: item.notes,
        };
      });
      if (this.hasDuplicates) {
        return;
      }
      const objekti = {
        deliverDate: this.movementDate,
        deliverLocationId: this.fromLocation,
        receiverLocationId: this.toLocation,
        teamNameId: this.teams,
        orderItemIds: orders,
        additionalMaterialVariantsInMovement: materials,
      }
      console.log('onSubmit', objekti)
      this.$emit('onSubmit', objekti)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.fromLocation = null
      this.teams = null
      this.toLocation = null
      if (this.orderItemIdss != this.orderItemIds) {
        this.orderItemIdss = this.orderItemIds
      }
      this.form = []
      this.additionalMaterials = false
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    totalPriceCalc(priceWithDiscount, length) {
      return (priceWithDiscount * length).toFixed(2);
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    addNewRow() {
      this.form.push({
        id: `s${Math.floor(Math.random() * 12000)}`,
        materialVariantId: '',
        name: '',
        measurementType: 'Meter',
        quantity: 0,
        notes: null,
      })
    },
    resetMeter(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },

    removeMaterialItem(id) {
      this.form = this.form.filter((item) => { return item.id !== id })
    },
    async getMVbyMeter(page, pageS) {
      // const { data } =
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        })
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariant == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariant != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariant == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariant != '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
  },
}
</script>

<style lang="scss">
.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    padding: 0px 34px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
 input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
</style>
